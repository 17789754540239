//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    treeHoleList: {
      type: Array
    },
    avatar: {
      type: String
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    launch: function launch() {
      this.$emit("launch");
    },
    deleteTreeHole: function deleteTreeHole(id) {
      this.$emit("deleteTreeHole", id);
    }
  }
};